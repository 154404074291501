import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Icon from "../custom-widgets/icon";

const LargePersonalizationCards = (props) => {
  const imgData = useStaticQuery(graphql`
    {
      card1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-greenlight-102324.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2AImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-business-banking-092524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2NevadaImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-nevada-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2IdahoImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-idaho-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2ArizonaImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-arizona-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2OregonImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-oregon-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2WashingtonImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-washington-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  /*
    NOTE: headings (Title) should be limited to 50 characters, and text (description) limited to 95 characters.
    as per design in Figma requirements.
  */
  const defaultCardData = [
    {
      // CARD SLOT 1
      slotNumber: 1,
      cards: [
        {
          cardNumber: 1,
          id: "home-large-personalization-card-1-default",
          imgData: imgData.card1ImageVar.childImageSharp.gatsbyImageData,
          altText: "Mother and son looking at the Greenlight application.",
          heading: "Help Your Kids Learn to Budget and Gift to Family with Greenlight",
          subheading: null,
          text: "Kids can manage their own money with parental controls and learn to budget within the app, courtesy of WaFd Bank.",
          linkUrl: "/personal-banking/greenlight-kids-debit-card",
          btnText: "Get Greenlight",
          stretchedLink: true
        }
      ]
    },
    {
      // CARD SLOT 2
      slotNumber: 2,
      cards: [
        // Default - Business Banking
        {
          cardNumber: 1,
          id: "home-large-personalization-card-2-default",
          imgData: imgData.card2AImageVar.childImageSharp.gatsbyImageData,
          altText: "Business owner and employee looking at a tablet.",
          heading: "Find a Business Banking Partner in Wafd Bank",
          text: "With personalized tools and support, WaFd is here to help your business succeed.",
          btnText: "Find Tools & Services",
          linkUrl: "/business-banking/small-business",
          stretchedLink: true
        },
        // Nevada - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-nevada",
          imgData: imgData.card2NevadaImageVar.childImageSharp.gatsbyImageData,
          altText: "Las Vegas skyline and residential area.",
          heading: "WaFd Bank's business clients thrive in our Nevada communities!",
          text: null,
          linkUrl: "/locations/nevada",
          btnText: "WaFd in Nevada",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "Terrible's",
              businessLink: "https://www.terribles.com/",
              businessLinkId: "nevada-card-sb-link-1",
              logoSrc: "/images/personalization/nevada-terribles-logo.svg"
            },
            {
              businessName: "Bonanza Gift Shop",
              businessLink: "https://bonanza-gift-shop.weeblyte.com/",
              businessLinkId: "nevada-card-sb-link-2",
              logoSrc: "/images/personalization/nevada-bonanza-gift-shop-logo.svg"
            },
            {
              businessName: "Doral Academy",
              businessLink: "https://www.doralacademynv.org",
              businessLinkId: "nevada-card-sb-link-3",
              logoSrc: "/images/personalization/nevada-doral-academy-logo.svg"
            }
          ]
        },
        // Idaho - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-idaho",
          imgData: imgData.card2IdahoImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown skyline in Boise, Idaho with hot air balloons.",
          heading: "WaFd Bank's business clients thrive in our Idaho communities!",
          text: null,
          linkUrl: "/locations/idaho",
          btnText: "WaFd in Idaho",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "The Yard Butler",
              businessLink: "https://theyardbutler.com/",
              businessLinkId: "idaho-card-sb-link-1",
              logoSrc: "/images/personalization/idaho-yard-butler-logo.svg"
            },
            {
              businessName: "Titanium Excavation",
              businessLink: "https://www.titaniumexcavation.com/",
              businessLinkId: "idaho-card-sb-link-2",
              logoSrc: "/images/personalization/idaho-titanium-excavation-logo.svg"
            },
            {
              businessName: "Take-5 Oil Change",
              businessLink: "https://www.t5idaho.com/locations",
              businessLinkId: "idaho-card-sb-link-3",
              logoSrc: "/images/personalization/idaho-take-5-oil-change-logo.png"
            }
          ]
        },
        // Arizona - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-arizona",
          imgData: imgData.card2ArizonaImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown skyline in Tucson, Arizona.",
          heading: "WaFd Bank's business clients thrive in our Arizona communities!",
          text: null,
          linkUrl: "/locations/arizona",
          btnText: "WaFd in Arizona",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "All City Towing",
              businessLink: "https://www.allcitytowing.com/",
              businessLinkId: "arizona-card-sb-link-3",
              logoSrc: "/images/personalization/arizona-all-city-towing-logo.svg"
            },
            {
              businessName: "iWired",
              businessLink: "https://www.iwired.com/contact-us/",
              businessLinkId: "arizona-card-sb-link-4",
              logoSrc: "/images/personalization/arizona-iwired-logo.svg"
            },
            {
              businessName: "Roadrunner Glass",
              businessLink: "https://www.roadrunnerglassco.com/",
              businessLinkId: "arizona-card-sb-link-2",
              logoSrc: "/images/personalization/arizona-roadrunner-glass-logo.svg"
            }
          ]
        },
        // Oregon - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-oregon",
          imgData: imgData.card2OregonImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown skyline in Portland, Oregon.",
          heading: "WaFd Bank's business clients thrive in our Oregon communities!",
          text: null,
          linkUrl: "/locations/oregon",
          btnText: "WaFd in Oregon",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "Bobcat Portland",
              businessLink: "https://www.bobcatofportland.com/",
              businessLinkId: "oregon-card-sb-link-1",
              logoSrc: "/images/personalization/oregon-bobcat-of-portland-logo.png"
            },
            {
              businessName: "PNW Metals",
              businessLink: "http://www.pnwmetal.com/",
              businessLinkId: "oregon-card-sb-link-2",
              logoSrc: "/images/personalization/oregon-pnw-metals-logo.png"
            },
            {
              businessName: "Oregon Water Services",
              businessLink: "https://oregonwaterservices.com",
              businessLinkId: "oregon-card-sb-link-3",
              logoSrc: "/images/personalization/oregon-oregon-water-services-logo.png"
            }
          ]
        },
        // Washington - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-washington",
          imgData: imgData.card2WashingtonImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown Seattle skyline with Space Needle.",
          heading: "WaFd Bank's business clients thrive in our Washington communities!",
          text: null,
          linkUrl: "/locations/washington",
          btnText: "WaFd in Washington",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "B&L Bicycles",
              businessLink: "https://www.bandlbicycles.com/",
              businessLinkId: "washington-card-sb-link-1",
              logoSrc: "/images/personalization/washington-bl-bicycles-logo.svg"
            },
            {
              businessName: "Absher",
              businessLink: "https://www.absherco.com/",
              businessLinkId: "washington-card-sb-link-2",
              logoSrc: "/images/personalization/washington-absher-logo.svg"
            },
            {
              businessName: "Field Bar",
              businessLink: "https://fieldtacoma.com",
              businessLinkId: "washington-card-sb-link-3",
              logoSrc: "/images/personalization/washington-field-bar-logo.svg"
            }
          ]
        }
      ]
    }
  ];
  // Use Props cardData if found, else use the default (used here due to imageVariable being used on the cards).
  const cardData = props.cardData || defaultCardData;
  const btnText = props.lang === "es" ? "Conoce más" : "Learn More";

  // NOTE: the slotCards and showHideClass logic below is for local build testing only, we can remove this show/hide logic once Tealium is handling that.
  const slotCards = props.defaultCardsArray; // show the defaults for now
  // defaultCardsArray picks the first card from each slot i.e., [1,1]

  /*
   * NOTE: please do NOT change the margin-bottom classes on these cards, it should be "mb-3 mb-lg-4".
   * Do NOT override the spacing using padding or margin classes on the props.containerClass.
   * If you need to override the section padding because the cards are connected
   * to other sections that need to look like one section, pass the sectionClass
   * "section-padding-top", "section-padding-none", "section-padding-bottom"
   */
  return (
    <div id="homepage-large-personalization-cards" className={`border-0 ${props.containerClass}`}>
      <div className="container">
        <ul className="row mb-0 pl-0 list-unstyled row-cols-1 row-cols-md-2">
          {cardData.map((slot, slotIndex) => {
            return (
              <li className="col-md mb-3 mb-lg-4 " key={slot.slotNumber}>
                <div id={`home-large-personalization-card-slot-${slot.slotNumber}`} className="h-100 ">
                  {slot.cards.map((card, cardIndex) => {
                    let showHideClass =
                      slotIndex + 1 === slot.slotNumber && card.cardNumber === slotCards[slotIndex]
                        ? "d-flex"
                        : "d-none";
                    return (
                      <div
                        id={card.id}
                        key={cardIndex}
                        tabIndex={0}
                        className={`card h-100 overflow-hidden border-radius-12 position-relative border-0 ${showHideClass} `}
                      >
                        {card.imgData && (
                          <GatsbyImage
                            className={`border-radius-12 border-radius-bottom-0`}
                            image={card.imgData}
                            alt={card.altText}
                          />
                        )}
                        <div className="card-body pb-0">
                          {card.heading && (
                            <h2 className="text-success font-weight-semi-bold" style={{ textDecoration: "none" }}>
                              {card.heading}
                            </h2>
                          )}
                          {card.text && <h4 dangerouslySetInnerHTML={{ __html: card.text }} />}
                          {card.stateSmallBusinesses &&
                            card.stateSmallBusinesses.map((sb, index) => (
                              <div className="row mb-4 align-items-center" key={index}>
                                <div className="col-auto">
                                  <img src={sb.logoSrc} alt={`${sb.businessName} Logo`} style={{ width: "120px" }} />
                                </div>
                                <div className="col">
                                  <h4 className="mb-0">
                                    <a
                                      className="text-decoration-none d-flex align-items-baseline"
                                      href={sb.businessLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      id={sb.businessLinkId}
                                    >
                                      <Icon name="external-link-alt" lib="far" class="mr-2" />
                                      {sb.businessName}
                                    </a>
                                  </h4>
                                </div>
                              </div>
                            ))}
                        </div>
                        {card.linkUrl && (
                          <div className="card-footer bg-white border-0 border-radius-bottom-12">
                            <div className="row">
                              <div className="col-sm-8 col-md-12 col-lg-6 position-static">
                                <Link
                                  id={`homepage-large-card-${card.cardNumber}-btn`}
                                  className={`btn btn-primary w-100 ${card.stretchedLink ? "stretched-link" : ""}`}
                                  to={card.linkUrl}
                                >
                                  {card.btnText || btnText}
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LargePersonalizationCards;
LargePersonalizationCards.defaultProps = {
  containerClass: "bg-info",
  defaultCardsArray: [1, 1],
  cardData: null,
  lang: "en"
};
