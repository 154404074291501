import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import Home from "../layouts/home";
import SEO from "../components/seo/seo";
import HeroChevron from "../components/hero/hero-chevron";
import PersonalizationCards from "../components/card/personalization-cards";
import LargePersonalizationCards from "../components/card/large-personalization-cards";
import ServiceStates from "../components/service-states/service-states";
import QuickLinks from "../components/quick-links/quick-links";
import BestBanksDefault from "../components/best-banks/best-banks-default";
import VideoModal from "../components/video-modal/video-modal";

// Helpers
import getHeroImgVariables from "../helpers/getHeroImgVariables";

const IndexPage = () => {
  // NOTE: the new chevron hero image file naming convention below uses the device size rather that the image size.
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const [showHelocVideo, setShowHelocVideo] = useState(false);
  const handleCloseHelocVideo = () => setShowHelocVideo(false);
  const handleShowHelocVideo = () => {
    setShowHelocVideo(true);
  };

  const heroChevronData = {
    id: "home-page-hero",
    ...getHeroImgVariables(pageData),
    altText: "A couple with a contractor looking at their home remodeling plans.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Peace of Mind with a 1% HELOC Discount"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            text: "Apply Now",
            url: "/personal-banking/home-loans/heloc-home-equity-line-of-credit",
            class: "btn-white",
            containerClass: "mb-2"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-btn-2",
            text: "What Can a HELOC Do",
            type: "button",
            class: "pl-0 py-0 text-white",
            containerClass: "mb-2",
            onClick: handleShowHelocVideo,
            icon: {
              position: "left",
              lib: "fas",
              name: "video",
              class: "float-left mt-1 mr-3"
            }
          }
        },
        {
          id: 4,
          tertiaryText: {
            class: "text-white",
            text: "Terms and conditions apply, subject to change and credit approval."
          }
        }
      ]
    }
  };

  const SEOData = {
    title: "WaFd Bank: Bank Accounts, Home Loans, and Commercial Financing",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "WaFd Bank: Bank Accounts, Home Loans, and Commercial Financing"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Washington Federal, now known as WaFd Bank, offers  services including checking and savings accounts, mortgages, HELOC, construction and lot loans."
      },
      {
        name: "google-site-verification",
        content: "_mFadtR6nMvgpFsiuNrksFw-FqJ9tQ1MMSNbp6-8XtE"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/homepage/og-heloc-102324.jpg"
      }
    ],
    schema: {
      "@context": "https://schema.org",
      "@type": "Organization",
      address: {
        "@type": "PostalAddress",
        addressLocality: "Seattle, Washington",
        postalCode: "98101",
        streetAddress: "425 Pike Street",
        description:
          "WaFd Bank, offers services including checking and savings accounts, mortgages, HELOC, construction and lot loans. Take advantage of all our research and tools, expert insight, and investment guidance to support your investing decisions."
      },
      email: "info@wafd.com",
      phoneNumber: "800-324-9375"
    },
    addSuffix: false
  };

  return (
    <Home footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <QuickLinks />
      <section className="bg-info">
        <PersonalizationCards />
        <LargePersonalizationCards />
        <ServiceStates />
      </section>
      <BestBanksDefault />
      <VideoModal
        show={showHelocVideo}
        handleClose={handleCloseHelocVideo}
        {...{
          id: "heloc-video-modal",
          VimeoID: "1014940338",
          Title: "Save 1% on HELOC Rate (Home Equity Line of Credit)",
          titleLink: "/personal-banking/home-loans/heloc-home-equity-line-of-credit"
        }}
      />
    </Home>
  );
};

export default IndexPage;
